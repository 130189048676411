<template>
  <div class="view-outer">
    <div class="view">
      <h2 class="title">Base64 编码、解码</h2>
      <h3>输入：</h3>
      <el-input
        type="textarea"
        v-model="inputText"
        :autosize="{ minRows: 5, maxRows: 15 }"
      ></el-input>
      <div class="toolbar">
        <el-button type="primary" @click="handleClickEncode"
          >编码 (Text -> Base64)</el-button
        >
        <el-button type="primary" @click="handleClickDecode"
          >解码 (Base64 -> Text)</el-button
        >
      </div>
      <h3>输出：</h3>
      <el-input
        type="textarea"
        v-model="outputText"
        :autosize="{ minRows: 5, maxRows: 15 }"
        readonly
      ></el-input>
    </div>
  </div>
</template>

<script>
import { Base64 } from "js-base64";

export default {
  name: "Base64",
  data() {
    return {
      inputText: "",
      outputText: "",
    };
  },

  methods: {
    handleClickEncode() {
      this.outputText = this.encode(this.inputText);
    },

    handleClickDecode() {
      this.outputText = this.decode(this.inputText);
    },

    encode(text) {
      return Base64.encode(text, false);
    },

    decode(b64str) {
      return Base64.decode(b64str);
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
}

.view-outer {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.view {
  text-align: left;
  background-color: lightgray;
  padding: 20px;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;
  box-shadow: 0px 0px 15px #888888;
  border-radius: 5px;
}

.toolbar {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
